/**
 * Run `npm run build:static-scripts` to build this script. It's not done automatically.
 */
import * as layout from "../../client/styles/layout";

const listColumns = () => {
    return document.querySelectorAll<HTMLDivElement>(".js-rl-footer-column");
};

const getColumnElems = (column: HTMLDivElement) => {
    return {
        checkbox: column.querySelector<HTMLInputElement>(
            "input.js-rl-footer-column-checkbox",
        ),
        title: column.querySelector<HTMLElement>(".js-rl-footer-column-label"),
        content: column.querySelector<HTMLElement>(
            ".js-rl-footer-column-content",
        ),
    };
};

const setColumnAttributes = ({
    column,
    title,
    content,
    isExpanded,
    isMobile,
}: {
    column: HTMLElement;
    title: HTMLElement;
    content: HTMLElement;
    isExpanded: boolean;
    isMobile: boolean;
}) => {
    const isCollapsible = column.classList.contains(
        "js-rl-footer-column--collapsable",
    );
    if (isMobile && isCollapsible) {
        title.tabIndex = 0;
        title.setAttribute("aria-expanded", isExpanded ? "true" : "false");
        content.hidden = !isExpanded;
    } else {
        title.removeAttribute("tabIndex");
        title.removeAttribute("aria-expanded");
        content.removeAttribute("hidden");
    }
};

const initColumn = (column: HTMLDivElement) => {
    const belowMedium = window.matchMedia(layout.belowMedium);
    const { checkbox, title, content } = getColumnElems(column);
    if (!checkbox || !title || !content) {
        console.error(
            "Footer column is missing one or more expected elements.",
        );
        return;
    }

    const updateAttrs = () => {
        setColumnAttributes({
            column: column,
            title: title,
            content: content,
            isExpanded: checkbox.checked,
            isMobile: belowMedium.matches,
        });
    };

    // Set initial aria attributes
    updateAttrs();

    // Watch the window width since accordions should only exist at mobile size.
    belowMedium.addEventListener("change", () => {
        updateAttrs();
    });

    // Update the aria attributes when the expand / collapse status changes.
    checkbox.addEventListener("change", (e) => {
        updateAttrs();
    });

    // Detect pressing ENTER when a label (column title) is focused. When this
    // happens, toggle the checkbox value.
    title.addEventListener("keyup", (event) => {
        if (event.key === "Enter") {
            checkbox.checked = !checkbox.checked;
        }
    });
};

document.addEventListener("DOMContentLoaded", (event) => {
    listColumns().forEach((column) => {
        initColumn(column);
    });
});
